<template lang="pug">
div
	main-nav-de(v-if="$i18n.locale === 'de-DE'")
	main-nav(v-else)
	slot(name='heading')
		v-img.heading.hidden-xs-only(:src="`${metaHeader}?width=1280`" height="247" gradient="180deg,rgba(37,63,86, 0.4) 0, rgba(2,11,31,0.4)" )
	.window.pull-up
		v-card(flat tile)
			v-flex.pa-3
				v-container(fluid)
					.content-grid
						div
							slot
						.sidebar
							portal-target(name="sidebar" multiple)
			v-btn(v-if="adminPath" target="_blank" :href="adminPath" small fab top right absolute color="primary")
				v-icon edit
</template>

<style></style>

<script>
import MainNav from '@/components/main/top-navigation.vue';
const MainNavDe = () =>
	import(
		/* webpackChunkName: "mainNavDE" */ '@/components/main/top-navigation.de.vue'
	);

export default {
	components: {
		MainNav,
		MainNavDe
	},
	props: {
		title: { type: String, default: 'Wintersport.nl' },
		metaHeader: {
			type: String,
			default: 'https://static.wintersport.nl/images/default-heading.jpg'
		}
	},
	computed: {
		adminPath() {
			return this.$store.state.meta.adminPath || null;
		}
	}
};
</script>
