<template lang="pug">
v-card.mb-3.cl-gray(outlined v-if="editorialNote != 'EDITORIALPLUS'")
	v-card-text.caption
		strong {{$t('page.weblog.message:editorial.note.title')}}
		p.mb-0
			template(v-if="editorialNote == 'EDITORIAL'") {{$t('page.weblog.message:editorial.note.editorial')}}
			template(v-if="editorialNote == 'AFFILIATE'") {{$t('page.weblog.message:editorial.note.affiliate')}}
			template(v-if="editorialNote == 'PROMOTIONAL'") {{$t('page.weblog.message:editorial.note.promotional')}}
			template(v-if="editorialNote == 'ADVERTORIAL'") {{$t('page.weblog.message:editorial.note.advertorial')}}
			nuxt-link(to="/noot-van-de-redactie" :title="$t('page.weblog.message:editorial.note.link.title')").text-no-wrap  &raquo; {{$t('page.weblog.message:editorial.note.link.text')}}
</template>

<script>
import gql from 'graphql-tag';

export default {
	name: 'EditorialNoteEmbed',
	props: {
		editorialNote: {
			type: String,
			required: true
		}
	},
	data() {
		return {};
	},
	fragments: gql`
		fragment EditorialNoteEmbedDataFragment on Embed {
			data {
				... on EmbedDataEditorialNote {
					editorialNote
				}
			}
		}
	`
};
</script>
