import dayjs from 'dayjs';

const regionBounds = {
	europe: [
		[35.0537, -7.75577],
		[56.0863, 30.4718]
	],
	alps: [
		[43.5317, 4.98676],
		[48.4116, 16.5236]
	],
	sauerland: [
		[50.8427, 7.92734],
		[51.4576, 9.29961]
	],
	krknose: [
		[50.380502, 14.738159],
		[50.999929, 16.243286]
	],
	ardennes: [
		[50.162824, 5.462952],
		[50.652073, 6.395416]
	],
	pyrenees: [
		[41.975827, -1.604004],
		[43.413029, 2.878418]
	]
};

const lngLatToPixels = function (lng, lat, mapHeight, mapWidth, bounds) {
	const pixels = [];

	if (typeof bounds === 'string') {
		bounds = regionBounds[bounds];
	}

	const lngDelta = bounds[1][1] - bounds[0][1];
	const lngOffset = lng - bounds[0][1];

	pixels.left = (lngOffset / lngDelta) * mapWidth;

	const mapLatBottom = bounds[0][0];
	const mapLatBottomDegree = (mapLatBottom * Math.PI) / 180;

	lat = (lat * Math.PI) / 180;
	const worldMapWidth = ((mapWidth / lngDelta) * 360) / (2 * Math.PI);

	const mapOffsetY =
		(worldMapWidth / 2) *
		Math.log(
			(1 + Math.sin(mapLatBottomDegree)) / (1 - Math.sin(mapLatBottomDegree))
		);
	pixels.top =
		mapHeight -
		((worldMapWidth / 2) * Math.log((1 + Math.sin(lat)) / (1 - Math.sin(lat))) -
			mapOffsetY);

	return pixels;
};

const latlngToPixels = function (lat, lng, mapHeight, mapWidth, bounds) {
	return lngLatToPixels(lng, lat, mapHeight, mapWidth, bounds);
};

const weathermapTypes = {
	snowforecast: {
		label: 'sneeuwverwachting',
		period: 0,
		forecastType: 'precip',
		imagetpl: '%e',
		timeindicator: '%s tot %e',
		cumulative: true,
		step: 3,
		starthour: 144,
		legend: 'legend-precip'
	},
	sun: {
		label: 'zon',
		period: 24,
		forecastType: 'sun',
		imagetpl: '%s_%e',
		timeindicator: '%s tot %e',
		step: 24,
		starthour: 24,
		legend: 'legend-sun'
	},
	snowradar: {
		label: 'radar',
		period: 3,
		forecastType: 'precip',
		imagetpl: '%s_%e',
		timeindicator: '%s tot %e',
		step: 3,
		starthour: 12,
		legend: 'legend-precip'
	},
	snowfall: {
		label: 'sneeuw per 24 uur',
		period: 24,
		forecastType: 'precip',
		imagetpl: '%s_%e',
		timeindicator: '%s tot %e',
		step: 3,
		starthour: 24,
		legend: 'legend-precip'
	},
	temperature: {
		label: 'temperatuur',
		period: 0,
		forecastType: 'temp',
		imagetpl: '%e',
		timeindicator: '%e',
		step: 3,
		starthour: 12,
		legend: 'legend-temp'
	},
	temp850: {
		label: 'temperatuur 850hpa',
		period: 0,
		forecastType: 'temp850',
		imagetpl: '%e',
		timeindicator: '%e',
		step: 3,
		starthour: 12,
		legend: 'legend-temp'
	},
	wind: {
		label: 'wind',
		period: 0,
		forecastType: 'wind',
		imagetpl: '%e',
		timeindicator: '%e',
		step: 3,
		starthour: 12,
		legend: 'legend-wind'
	},
	clouds: {
		label: 'wolken',
		period: 0,
		forecastType: 'clouds',
		imagetpl: '%e',
		timeindicator: '%e',
		step: 3,
		starthour: 12,
		legend: false
	},
	pressure: {
		label: 'luchtdruk',
		period: 0,
		forecastType: 'pressure',
		imagetpl: '%e',
		timeindicator: '%e',
		step: 3,
		starthour: 12,
		legend: false
	},
	jetstream: {
		label: 'jetstream',
		period: 0,
		forecastType: 'jetstream',
		imagetpl: '%e',
		timeindicator: '%e',
		step: 3,
		starthour: 12,
		legend: 'legend-jetstream'
	}
};

const forecastOverlayUrl = (rundate, runhour, type, hour, region = 'alps') => {
	const typeobj = weathermapTypes[type] || weathermapTypes.snowforecast;
	hour = hour || typeobj.starthour;

	const startDate = dayjs(`${rundate} ${runhour}:00:00`).add(6, 'hour');

	const image = typeobj.imagetpl
		.replace(
			/%s/,
			startDate.add(hour - typeobj.period, 'hour').format('YYYY-MM-DD_HH')
		)
		.replace(/%e/, startDate.add(hour, 'hour').format('YYYY-MM-DD_HH'));

	return `https://maps.nskiv.com/${region}/${typeobj.forecastType}/${rundate}_${runhour}/${image}.png`;
};

export {
	regionBounds,
	lngLatToPixels,
	latlngToPixels,
	forecastOverlayUrl,
	weathermapTypes
};
