<template lang="pug">
ws-dialog(v-model="dialog" :title="$t('login.modal:heading')")
	v-alert.mb-3(:value="showActivated" type="success" color="success" border="bottom") {{$t('page.login:account.activated')}}
	v-alert.mb-3(:value="error.show" type="error" color="error") {{error.message}}
	v-card-text.mt-3
		validation-observer(v-slot="{ handleSubmit, invalid }")
			v-form(@submit.prevent="handleSubmit(onSubmit)" @keyup.native.enter="handleSubmit(onSubmit)")
				validation-provider(v-slot="{errors, touched}" rules="required|email" :name="$t('page.login:email.field.label')" slim)
					v-text-field.mb-3(v-model="email" :label="$t('page.login:email.field.label')" required outlined hide-details="auto" :error-messages="touched ? errors : []")
				validation-provider(v-slot="{errors, touched}" rules="required" :name="$t('page.login:password.field.label')" slim)
					v-text-field.mb-3(v-model="password" type="password" :label="$t('page.login:password.field.label')" required outlined hide-details="auto"  :error-messages="touched ? errors : []")
				template(v-if="step === '2fa'")
					validation-provider.mb-3(v-slot="{errors, touched}" rules="required|digits:6" name="token" slim)
						v-text-field(v-model.trim="token" outlined label="2FA Token" autofocus :error-messages="touched ? errors : []" hide-details="auto")

				v-btn(type="submit" block color="accent" depressed :disabled="invalid") {{$t('page.login:login.button')}}
	div.text-center.pb-4
		nuxt-link.d-block(:to="localePath({name:'forgot'})") {{$t('page.login:forgot.password.link')}}
		nuxt-link.d-block(:to="localePath({name:'register', query: {redirect: encodeURIComponent($route.fullPath)}})") {{$t('page.login:register.link')}}

</template>

<script>
import gql from 'graphql-tag';

export default {
	props: {
		value: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			dialog: false,
			showForm: true,
			showActivated: false,
			email: '',
			password: '',
			token: '',
			error: {
				show: false,
				text: null
			},
			step: 'login'
		};
	},
	watch: {
		value(value, _oldValue) {
			this.dialog = value;
		},
		dialog(value, _oldValue) {
			this.$emit('input', value);
		}
	},
	methods: {
		async onSubmit() {
			const sessionToken = await this.$graphClient
				.mutate({
					mutation: gql`
						mutation ($input: createSessionInput!) {
							sessionToken: createSession(input: $input)
						}
					`,
					variables: {
						input: {
							email: this.email,
							password: this.password,
							token: this.token
						}
					}
				})
				.then(data => {
					if (data.errors) {
						switch (data.errors[0].extensions.code) {
							case 'missing-2fa': {
								this.step = '2fa';

								break;
							}
							case 'invalid-credentials': {
								this.error = {
									show: true,
									message: this.$t('page.login:error.login.failed')
								};

								break;
							}
							case 'invalid-2fa': {
								this.error = {
									show: true,
									message: this.$t('page.login:error.login.2fa')
								};

								break;
							}
							default: {
								this.error = {
									show: true,
									message: this.$t('page.login:error.login.failed')
								};
								this.$toast.error(data.errors[0].message);
							}
						}
					}

					return data.data.sessionToken || null;
				})
				.catch(error => {
					this.$toast.error(error.message);
				});

			if (sessionToken) {
				await this.$apolloHelpers.onLogin(
					sessionToken,
					this.$i18n.locale.slice(0, 2)
				);
				await this.$store.dispatch('checkSession');
				window.location = this.$route.query.redirect
					? decodeURIComponent(this.$route.query.redirect)
					: '/';
			}
		},
		errorMessage(errors) {
			return errors.map(error => error.message[0]).join('\n');
		}
	}
};
</script>
