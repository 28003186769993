<template lang="pug">
div.mb-6
	v-skeleton-loader(:type="skeletonType" v-if="!isFinished" v-intersect.once="onIntersect")
	v-card.mb-6(v-for="item in items" :key="item.SportmomentId")
		v-container
			v-row
				v-col.shrink.image(cols="12" md="auto")
					a(:href="item.detailUrl" target="_blank")
						v-img(:src="item.AfbeeldingUrl.replace('http://', 'https://')" :width="$vuetify.breakpoint.smAndUp ? 200: 'auto'" min-width="200" :aspect-ratio="16/10")
				v-col.grow
					a.text-decoration-none( :href="item.detailUrl" target="_blank")
						.text-h5.font-weight-bold {{item.Naam}}
					factline
						li(icon="calendar") {{$dayjs(item.BeginDatum).format('dddd DD MMMM')}}
						li(icon="clock-o") {{$dayjs(item.BeginDatum).format('HH:mm')}} - {{$dayjs(item.EindDatum).format('HH:mm')}}
					factline
						li(icon="map-marker")
							em(v-if="item.LocatieItem.Naam !== item.LocatieItem.Adres") {{item.LocatieItem.Naam}},&nbsp;
							span {{item.LocatieItem.Adres}} {{item.LocatieItem.Woonplaats}}
					factline
						li(icon="tags") {{item.SportenTranslated.join(' - ')}}
					//- .text-body-1 {{item.ExtraInformatie}}
					a.d-block.text-decoration-none(:href="item.detailUrl" target="_blank") » meer informatie
				v-col.text-center.shrink
					.text-h4.primary--text.font-weight-bold {{$n(item.Kosten, 'currency', $i18n.locale)}}
					.caption.text-no-wrap.mb-3 nog {{item.TotaalAantalPlaatsen - item.TotaalAantalInschrijvingen}} plekken beschikbaar
					v-btn( depressed color="primary" :href="item.bookingUrl" target="_blank") nu boeken

</template>

<script>
// import { useFetch } from '@vueuse/core';
import gql from 'graphql-tag';

import axios from 'axios';

// import { ref, computed } from '@vue/composition-api';

export default {
	name: 'EmbedSportmatch',
	props: {
		sportmatchId: {
			type: String,
			required: true
		}
	},
	// setup(props) {
	// 	const intersected = ref(false);

	// 	const sportmatchId = computed(() => props.sportmatchId);

	// 	const url = computed(() => {
	// 		if (!intersected.value) {
	// 			return null;
	// 		}

	// 		return `${sportMomentUrl}/search?OrderBy=StartDatum&sportaanbieder=${sportmatchId.value}&platform_facet=18&type=sportaanbod&host=wintersport.nl`;
	// 	});

	// 	const { execute, isFinished, data } = useFetch(url, {
	// 		immediate: false,
	// 		refetch: true,
	// 		initialData: { Items: [] }
	// 	}).json();

	// 	const skeletonType = `list-item-avatar@${props.maxItems || 10}`;

	// 	const items = computed(() =>
	// 		data.value.Items.map(item => {
	// 			return {
	// 				...item.Entity,
	// 				bookingUrl: `${bookingBaseUrl}/sportactiviteit-detail/reserveren?id=${item.Entity.SportmomentId}`,
	// 				detailUrl: `${bookingBaseUrl}/sportactiviteit-detail?id=${item.Entity.SportmomentId}`
	// 			};
	// 		})
	// 	);

	// 	return {
	// 		execute,
	// 		isFinished,
	// 		items,
	// 		skeletonType,
	// 		intersected,
	// 	};
	// },
	data: () => {
		return {
			items: [],
			skeletonType: 'list-item-avatar@5',
			isFinished: false
		};
	},
	computed: {
		url() {
			return `${this.$env.SPORTMATCH_API_URL}/search?OrderBy=StartDatum&sportaanbieder=${this.sportmatchId}&platform_facet=18&type=sportaanbod&host=wintersport.nl`;
		}
	},
	methods: {
		onIntersect() {
			// this.intersected.value = true;
			this.fetchItems();
		},
		fetchItems() {
			axios
				.get(this.url)
				.then(({ data }) => {
					this.items = data.Items.map(item => {
						const bookingUrl = `${this.$env.SPORTMATCH_DETAIL_URL}/sportactiviteit-detail/reserveren?id=${item.Entity.SportmomentId}`;
						const detailUrl = `${this.$env.SPORTMATCH_DETAIL_URL}/sportactiviteit-detail?id=${item.Entity.SportmomentId}`;

						return {
							...item.Entity,
							bookingUrl: `/redir?u=${encodeURIComponent(
								bookingUrl
							)}&p=sportmatch&t=booking&h=${this.$redirHash(bookingUrl)}`,
							detailUrl: `/redir?u=${encodeURIComponent(
								detailUrl
							)}&p=sportmatch&t=detail&h=${this.$redirHash(detailUrl)}`
						};
					});
				})
				.finally(() => {
					this.isFinished = true;
				});
		}
		// 	// getDetails(sportmomentId) {
		// 	// 	const detailUrl = `${sportMomentUrl}/api/sportmoment?Platform=18&id=${sportmomentId}`;
		// 	// }
	},
	fragments: gql`
		fragment SportmatchEmbedDataFragment on Embed {
			data {
				... on EmbedDataSportmatch {
					sportmatchId
				}
			}
		}
	`
};
</script>
