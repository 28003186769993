import Vue from 'vue';

export default (context, inject) => {
	context.app.breadcrumbs = breadcrumbs => {
		breadcrumbs = Object.keys(breadcrumbs).map(key => {
			return {
				text: key,
				to: context.app.localePath(breadcrumbs[key])
			};
		});

		context.store.commit('SET_BREADCRUMBS', breadcrumbs);
	};

	// @deprecated
	context.app.title = title => {
		context.store.commit('SET_META', { title });
	};
	// @end-deprecated

	context.app.meta = meta => {
		if (Vue.$gtag) {
			Vue.$gtag.pageview({
				page_title: meta.title,
				page_path: context.route.fullPath
			});
		}

		context.store.commit('SET_META', context.app.$lodash.cloneDeep(meta));
	};

	context.app.ads = ads => {
		context.store.commit('SET_ADS', ads);
	};

	context.app.areaBreadcrumbs = (area, breadcrumbs) => {
		if (!area.country || !area.region) {
			throw new Error('Country or Region data is missing');
		}

		const areaBreadcrumb = {
			[area.country.name]: {
				name: 'areas-countries-country',
				params: { country: area.country.slug }
			},
			[area.region.name]: {
				name: 'areas-regions-region',
				params: { region: area.region.slug }
			},
			[area.name]: {
				name: 'areas-area',
				params: { area: area.slug }
			}
		};

		context.app.breadcrumbs({ ...areaBreadcrumb, ...breadcrumbs });
	};

	context.app.resortBreadcrumbs = (resort, breadcrumbs) => {
		if (!resort.country || !resort.region) {
			throw new Error('Country or Region data is missing');
		}

		const areaBreadcrumb = {
			[resort.country.name]: {
				name: 'areas-countries-country',
				params: { country: resort.country.slug }
			},
			[resort.region.name]: {
				name: 'areas-regions-region',
				params: { region: resort.region.slug }
			},
			// Even prefixen omdat er soms dorpen zijn met zelfde naam
			// Dit wordt gestript op packages/site/components/breadcrumbs.vue:56
			['Area:' + resort.area.name]: {
				name: 'areas-area',
				params: { area: resort.area.slug }
			},
			[resort.name]: {
				name: 'resorts-resort',
				params: { resort: resort.slug }
			}
		};

		context.app.breadcrumbs({ ...areaBreadcrumb, ...breadcrumbs });
	};

	inject('structuredData', () => {
		const data = {
			__dangerouslyDisableSanitizers: ['script'],
			script: []
		};

		if (context.store.state.breadcrumbs.length) {
			const structuredBreadcrumbs = {
				'@context': 'http://schema.org',
				'@type': 'BreadcrumbList',
				itemListElement: [
					{
						'@type': 'ListItem',
						position: 1,
						name: 'Wintersport',
						item:
							'https://' +
							context.store.state.localeDomains[context.store.state.i18n.locale]
					},
					...context.store.state.breadcrumbs.map((crumb, index) => {
						return {
							'@type': 'ListItem',
							position: index + 2,
							name: crumb.text,
							item: `${
								'https://' +
								context.store.state.localeDomains[
									context.store.state.i18n.locale
								]
							}${context.app.router.resolve(crumb.to).href}`
						};
					})
				]
			};
			data.script.push({
				innerHTML: JSON.stringify(structuredBreadcrumbs),
				type: 'application/ld+json',
				hid: 'breadcrumbs'
			});
		}

		Object.entries(context.store.state.jsonld)
			.filter(([_type, content]) => !!content)
			.forEach(([type, content]) => {
				data.script.push({
					innerHTML: JSON.stringify({
						'@context': 'http://schema.org',
						'@type': type,
						...content
					}),
					type: 'application/ld+json',
					hid: type
				});
			});

		if (context.store.state.meta) {
			const structuredCreativeWork = {
				'@context': 'http://schema.org',
				'@type': 'CreativeWork',
				dateCreated: context.store.state.meta.created_at
				// dateModified: context.store.state.meta.created_at deze alleen gebruiken als we de echte modified date hebben
			};
			data.script.push({
				innerHTML: JSON.stringify(structuredCreativeWork),
				type: 'application/ld+json',
				hid: 'contentpage-schema'
			});
		}

		data.title = context.store.state.meta.title;
		data.description = context.store.state.meta.description;
		data.htmlAttrs = {
			lang: context.store.state.i18n.locale
		};
		data.link = [];

		const pagination = context.store.state.meta.pagination;
		if (pagination) {
			if (pagination.total > 1 && pagination.page < pagination.total) {
				data.link.push({
					hid: 'link_next',
					rel: 'next',
					href: context.app.router.resolve({
						...context.route,
						query: { page: pagination.page + 1 }
					}).href
				});
			}
			if (pagination.page > 1) {
				data.link.push({
					hid: 'link_prev',
					rel: 'prev',
					href: context.app.router.resolve({
						...context.route,
						query: pagination.page > 2 ? { page: pagination.page - 1 } : {}
					}).href
				});
				data.description = null;
				data.title += context.app.i18n.t('global.pagination.title', {
					page: pagination.page,
					total: pagination.total
				});
			}
		}

		if (context.store.state.meta.canonical) {
			data.link.push({
				hid: 'thispage_canonical',
				rel: 'canonical',
				href: context.store.state.meta.canonical
			});
		} else {
			data.link.push({
				hid: 'thispage_canonical',
				rel: 'canonical',
				href:
					'https://' +
					context.store.state.localeDomains[context.store.state.i18n.locale] +
					context.route.fullPath
			});
		}

		const defaulHrefLang =
			context.store.state.i18n.locale === 'de-DE'
				? {
						de:
							'https://' +
							context.store.state.localeDomains['de-DE'] +
							context.route.fullPath
				  }
				: {
						'nl-NL':
							'https://' +
							context.store.state.localeDomains['nl-NL'] +
							context.route.fullPath,
						'nl-BE':
							'https://' +
							context.store.state.localeDomains['nl-BE'] +
							context.route.fullPath
				  };

		const hreflang = context.store.state.meta.hreflang || defaulHrefLang;

		if (hreflang) {
			Object.keys(hreflang).forEach(iso => {
				data.link.push({
					hid: `${iso}_hreflang`,
					rel: 'alternate',
					hreflang: iso,
					href: hreflang[iso]
				});
			});

			if (hreflang['nl-NL']) {
				data.link.push({
					hid: 'default_hreflang',
					rel: 'alternate',
					hreflang: 'x-default',
					href: hreflang['nl-NL']
				});
			}
		}

		data.meta = [];
		if (context.store.state.meta.description) {
			data.meta.push({
				hid: 'description',
				name: 'description',
				content: context.store.state.meta.description.substring(0, 160)
			});
		}

		data.meta.push({
			hid: 'og_image',
			property: 'og:image',
			content: context.store.state.meta.image
				? `${context.store.state.meta.image}?width=1200`
				: 'https://static.wintersport.nl/images/ogimages/ws-wintersporters.jpg'
		});

		data.meta.push({
			hid: 'og_image_url',
			property: 'og:image:url',
			content: context.store.state.meta.image
				? `${context.store.state.meta.image}?width=1200`
				: 'https://static.wintersport.nl/images/ogimages/ws-wintersporters.jpg'
		});

		data.meta.push({
			hid: 'og_image_secure_url',
			property: 'og:image:secure_url',
			content: context.store.state.meta.image
				? `${context.store.state.meta.image}?width=1200`
				: 'https://static.wintersport.nl/images/ogimages/ws-wintersporters.jpg'
		});

		data.meta.push({
			hid: 'og_type',
			property: 'og:type',
			content: context.store.state.meta.type || 'website'
		});

		data.meta.push({
			hid: 'og_title',
			property: 'og:title',
			content: context.store.state.meta.title
		});

		data.meta.push({
			hid: 'og_description',
			property: 'og:description',
			content: context.store.state.meta.description
		});

		data.meta.push({
			hid: 'og_url',
			property: 'og:url',
			content:
				'https://' +
				context.store.state.localeDomains[context.store.state.i18n.locale] +
				context.route.fullPath
		});

		data.meta.push({
			hid: 'robots_preview',
			name: 'robots',
			content: 'max-image-preview:large'
		});

		data.meta.push({
			hid: 'twitter_card',
			name: 'twitter:card',
			content: context.store.state.meta.twitterCardType || 'summary'
		});

		if (['nl-NL', 'nl-BE'].includes(context.store.state.i18n.locale)) {
			data.meta.push({
				hid: 'twitter_site',
				name: 'twitter:site',
				content: '@wintersporters'
			});
		}

		data.meta.push({
			hid: 'twitter_title',
			name: 'twitter:title',
			content: context.store.state.meta.title
		});

		data.meta.push({
			hid: 'twitter_description',
			name: 'twitter:description',
			content: context.store.state.meta.description
		});

		data.meta.push({
			hid: 'twitter_image',
			name: 'twitter:image',
			content:
				context.store.state.meta.image ||
				'https://static.wintersport.nl/images/ogimages/ws-wintersporters.jpg'
		});

		if (context.app.$env.APP_ENV === 'production') {
			data.meta.push({
				hid: 'robots',
				name: 'robots',
				content: `${
					context.store.state.meta.hasIndex ? 'index' : 'noindex'
				}, follow`
			});
			data.meta.push({
				hid: 'ahrefs-site-verification',
				name: 'ahrefs-site-verification',
				content: `189463eaff58d0efff9a4626e90ea78d7ae7509bbcbed5746468586943c07524`
			});
			data.meta.push({
				hid: 'tradetracker-site-verification',
				name: 'tradetracker-site-verification',
				content: `464a1afc98eddc685a2d8e7c72870b11d872d6d7`
			});
			data.meta.push({
				hid: 'google-site-verification',
				name: 'google-site-verification',
				content: `Tkvp-K7Hp5RAEBrtaRs4XW_FBeqg5EW_iV87lWGCDU8`
			});
		} else {
			data.meta.push({
				hid: 'robots',
				name: 'robots',
				content: 'noindex, follow'
			});
		}

		return data;
	});
};
