<template lang="pug">
div(v-resize="onResize")
	slot(name="heading")
		v-img.heading.hidden-xs-only(:src="`${metaHeader}?width=1280`" height="247" gradient="180deg,rgba(37,63,86, 0.4) 0, rgba(2,11,31,0.4)" )
	.window.pull-up(ref="window")
		v-card(flat tile)
			v-container.pt-9
				v-row(align="start")
					v-col.px-6.py-0.shrink.hidden-xs-only
						.navigation(ref="navigation")
							portal(to="drawer-nav-icon")
								v-icon {{pagenavIcon}}
							portal(to="drawer-nav" :disabled="!showMobilePageNav")
								slot(name="nav")
					v-divider.hidden-xs-only(vertical)
					v-col.main-content(:style="{maxWidth:`${$store.state.content_width.full-1}px`}")
						breadcrumbs
						portal-target(name="full-width")
						div(v-if="!v2")
							.infosheet( ref="infosheet" v-if="$slots.sidebar")
								slot(name="sidebar")
							//- .sidebar
								portal-target(name="sidebar" multiple)
							slot
						div.content-grid(v-else)
							div
								slot
							.sidebar
								portal-target(name="sidebar" multiple)
</template>

<style lang="sass">
.navigation
	@media #{map-get($display-breakpoints, 'sm-and-up')}
		width: 190px
	@media #{map-get($display-breakpoints, 'md-and-up')}
		width: 240px

.main-content
	@media #{map-get($display-breakpoints, 'md-and-up')}
		padding: 0 6 * $spacer !important

h1
	@media #{map-get($display-breakpoints, 'sm-and-down')}
		display: none
</style>

<script>
import Breadcrumbs from '@/components/breadcrumbs';

export default {
	components: {
		Breadcrumbs
	},
	props: {
		pagenavIcon: { type: String, default: 'menu' },
		v2: { type: Boolean, default: false }
	},
	data() {
		return {
			isMounted: false,
			windowStyle: null
			// infosheetStyle: null
		};
	},
	computed: {
		showMobilePageNav() {
			return this.isMounted && this.$vuetify.breakpoint.xsOnly;
		},
		metaHeader() {
			return (
				this.$store.state.meta.header ||
				'https://static.wintersport.nl/images/default-heading.jpg'
			);
		}
	},
	mounted() {
		this.windowStyle = getComputedStyle(this.$refs.window);
		this.navigationStyle = getComputedStyle(this.$refs.navigation);
		this.isMounted = true;
		this.$nextTick(() => {
			this.onResize();
		});
	},
	methods: {
		onResize(_event) {
			if (!this.windowStyle) {
				return false;
			}
			const windowWidth =
				parseFloat(this.windowStyle.width) -
				parseFloat(this.windowStyle.paddingLeft) -
				parseFloat(this.windowStyle.paddingRight);

			// let infosheetWidth = 0;
			// if (this.infosheetStyle) {
			// 	infosheetWidth = parseFloat(this.infosheetStyle.width);
			// }

			const infosheetWidth = this.$vuetify.breakpoint.lgAndDown
				? this.$vuetify.breakpoint.mdAndDown
					? this.$vuetify.breakpoint.smAndDown
						? 24
						: 244
					: 374
				: 401;

			let navigationWidth = 0;
			let navigationPaddings = 0;
			if (this.navigationStyle && !this.$vuetify.breakpoint.xsOnly) {
				navigationWidth = parseFloat(this.navigationStyle.width);
				navigationPaddings = 48;
			}

			const availableContentWidth =
				windowWidth - navigationWidth - navigationPaddings;

			this.$store.commit('SET_CONTENT_WIDTH', {
				full: Math.floor(availableContentWidth),
				full_unpadded: Math.floor(availableContentWidth) - 48,
				infosheet: Math.floor(availableContentWidth - infosheetWidth - 48)
			});
		}
	}
};
</script>
